import logo from './images/debugmonster_logo.png';
import logo1 from './images/debugmonster.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo}></img>
      <img src={logo1}></img>
    </div>
  );
}

export default App;
